<template>
    <a-layout>
        <a-layout-content>
            <a-row>
                <a-col :span="24">
                    <div class="headarea">
                        <a-card class="rowcard" :bordered="false" :bodyStyle="{margin:0,padding:0}" 
                            :headStyle="{fontWeight:'normal',backgroundColor:'#f8f9fc'}"
                            :title="tickettitle" :style="{borderRadius:'2px',margin:0}">
                        </a-card>
                    </div>
                    <a-row class="contactarea" ref="refmessages">
                        <a-skeleton active :loading="loading_messages" :style="{padding:'30px 20px'}">
                        <a-col v-for="ticket in ticketreplies" :span="24" >
                            <div class="messages" :class="ticket.iscustom?'custom':'service'">
                                <div class="msgtitle">{{ticket.posttime}}</div>
                                <div>
                                    <span class="msgcontent" :class="ticket.iscustom?'fromcustom':'fromservice'">
                                        {{ticket.content}}
                                    </span>
                                </div>
                            </div>
                        </a-col>
                        </a-skeleton>
                    </a-row>
                    <div class="inputarea">
                        <a-input-search v-model:value="inputtext" :disabled="loading_poststate" :placeholder="$langsmgr('langkey.component.ticket.msg.holder')"
                            :bordered="false" @search="postreply">
                            <template #enterButton>
                                <a-button type="primary" danger>{{$langsmgr('langkey.component.ticket.msg.submit')}}</a-button>
                            </template>
                        </a-input-search>
                    </div>
                </a-col>
            </a-row>
        </a-layout-content>
    </a-layout>
</template>

<script setup>
import { ref,getCurrentInstance, onMounted, nextTick } from 'vue'
import { message } from 'ant-design-vue';
import axios from 'axios'

const ginstance = getCurrentInstance()
const gconfig = ginstance.appContext.config.globalProperties;
const inputtext = ref('')
const refmessages = ref()
const tickettitle = ref('')
const ticketstate = ref(-0x01)
const ticketreplies = ref([])

const loading_messages = ref(true)
const loading_poststate = ref(false)


var customname = gconfig.$storage.get(gconfig.storagekey_fep_vsignname).value
console.log(ginstance)

onMounted(()=>{



    loading_messages.value = true

    axios.get(`${gconfig.$backendbase}/order/v3/getorderdetails`,{
        params:{
            ticketid: gconfig.$querystring("t"),
            r:Math.random(),
        },
        headers: gconfig.$getauthheaders()
    }).then(resp=>{
        if(resp.data.data.result){
            tickettitle.value = resp.data.data.title
            ticketstate.value = resp.data.data.state
            ticketreplies.value.push({
                iscustom: true,
                content: decodeURIComponent(resp.data.data.content),
                posttime: resp.data.data.pubtime
            })
            for(var i = 0x00; i < resp.data.data.replies.length; i++){
                var __reply = resp.data.data.replies[i]
                ticketreplies.value.push({
                    iscustom: __reply.iscustom,
                    content: decodeURIComponent(__reply.content),
                    posttime: __reply.pubtime
                })
            }
        }
        loading_messages.value = false
    }).catch(err=>{
        gconfig.$axiosErrorHandle(err)
    })
})

const postreply = () => {
    if(ticketstate.value !=0x00 && ticketstate.value != 0x01){
        message.error(gconfig.$langsmgr('langkey.component.ticket.msg.closed'))
        return
    }
    if(!inputtext.value||inputtext.value.length==0x00){
        message.error(gconfig.$langsmgr('langkey.component.ticket.msg.empty'))
        return
    }
    loading_poststate.value = true
    axios.post(`${gconfig.$backendbase}/order/v3/postreply`,{
        ticketid: gconfig.$querystring("t"),
        reply: encodeURIComponent(inputtext.value)
    },{
        headers: gconfig.$getauthheaders()
    }).then(resp=>{
        if(resp.data.data.result){
            ticketreplies.value.push({
                iscustom: true,
                content: decodeURIComponent(decodeURIComponent(resp.data.data.content)),
                posttime: resp.data.data.pubtime
            })
        }else{
            message.error(gconfig.$langsmgr('langkey.component.ticket.msg.error'))
        }
        loading_poststate.value = false
    }).catch(err=>{
        gconfig.$axiosErrorHandle(err)
    })

    inputtext.value = ''
}

</script>

<style scoped>
*{
    background-color: #f0f3f8;
}
.clearfix{
    clear:both;
}
.inputarea {
    position:fixed;
    left:0;
    right:0;
    bottom:0;
    height: 50px;
    background-color:#e4e9f3;
    padding:10px 10px 0 10px;
}
.headarea{
    position: fixed;
    top:0;
    z-index:100;
    width:100%;
}
.contactarea{
    margin:50px 0 80px 0;
}
.messages{
    padding:10px 20px 0 20px;
}
.msgtitle {
    padding:10px 0;
    font-size: 14px;
    color:#666666;
}
.msgcontent{
    display:block;
    padding:10px 15px;
    line-height:26px;
    font-size: 16px;
    border-radius: 4px;
    max-width:90%;
}
.service{
    text-align: left;
}
.custom{
    text-align: right;
}
.fromcustom{
    background-color: #e4efd8;
    text-align: left;
    float: right;
}
.fromservice{
    background-color: #f8f9fa;
    text-align: left;
    float: left;
}
.ticketclosed{
    margin:0;
    padding:0;
    line-height: 50px;
    color:#999999;
    background-color: transparent;
}
</style>