<template>
    <div class="bglayout">
        <video id="bgvdo" ref="bgvideo" muted src="/static/bg.mp4" 
            :width="winsize.width"
            :height="winsize.height"
            autoplay loop></video>
    </div>
    <div class="loginbg">
        <div class="loginpad">
            <div class="loginform" :class="ismobile?'widthifmobile':'widthifpc'">
                <div class="padtitle">{{ $langsmgr("langkey.sitename") }}</div>
                <div class="padsubtitle">{{ $langsmgr("langkey.sitesubtitle") }}</div>
                <div v-if="isenvchecked" class="loginarea">
                    <div class="loginarea-line">
                        <a-input ref="unameinput" v-model:value="unameval" 
                            :disabled="isnotcheck" :placeholder="$langsmgr('langkey.component.login.uname.placeholder')"
                            @pressEnter="login()">
                            <template #prefix>
                                <MailOutlined/>
                            </template>
                        </a-input>
                    </div>
                    <div class="loginarea-line">
                        
                    </div>
                    <div class="loginarea-line">
                        <a-input-password ref="upasswdinput" v-model:value="upasswdval" 
                            :disabled="isnotcheck" :placeholder="$langsmgr('langkey.component.login.passwd.placeholder')"
                            @pressEnter="login()">
                            <template #prefix>
                                <edit-outlined/>
                            </template>
                        </a-input-password>
                    </div>
                    <!-- <div v-if="false" class="loginarea-line">
                        <span class="recaptchacheck">
                            <CheckOutlined v-if="!isnotcheck" class="checkoutlined"/>
                            <a-button :type="recaptchatype" size="small" :loading="isrecaptchachecking" :disabled="!isnotcheck" @click="recaptcha">{{recaptchatext}}</a-button>
                        </span>
                    </div> -->
                    <div class="loginarea-line">
                        <span class="remeline">
                            <a-checkbox class="remeckbox"> {{$langsmgr('langkey.component.login.remeberme')}}</a-checkbox>
                        </span>
                    </div>
                    <div class="clearfix"></div>
                    <div class="loginarea-line loginbtnline">
                        <a-button type="primary" size="large" :class="'fakebtn'" :loading="isloginning" :disabled="isnotcheck" 
                            @click="login()">
                            <LoginOutlined/>
                            {{logintext}}
                        </a-button>
                    </div>
                </div>
                <div v-else class="loading">
                    <LoadingOutlined/>
                    <a class="loading-text">{{$langsmgr('langkey.component.login.preloading')}}</a>
                </div>
                <CPRArea type="signin"/>
            </div>
        </div>
    </div>
</template>
<script setup>
import { LoadingOutlined,UserOutlined,EditOutlined,CheckOutlined,MailOutlined,LoginOutlined } from '@ant-design/icons-vue'
//import { useReCaptcha } from 'vue-recaptcha-v3'
import { getCurrentInstance, ref, nextTick, onMounted } from 'vue'
import axios from 'axios'
import { notification,message } from 'ant-design-vue'
import CPRArea from './cprarea.vue'

const gconfig = getCurrentInstance().appContext.config.globalProperties;

const ismobile = ref(gconfig.$ismobile())

const unameinput = ref(null)
const upasswdinput = ref(null)
const unameval = ref('')
const upasswdval = ref('')

const bgvideo = ref()

const state = ref(false)
const isenvchecked = ref(false)
const isnotcheck = ref(false)
const isloginning = ref(false)
const isrecaptchachecking = ref(false)
const recaptchatype = ref('primary')
const logintext = ref(null)
const recaptchatext = ref(null)
const gtoken = ref(null)

const winsize = ref({
    width: null,
    height: null
})
const whenWindowResize = ()=>{
    winsize.value.width = window.innerWidth
    winsize.value.height = window.innerWidth
}

var __temp_sign = ""

//const {executeRecaptcha, recaptchaLoaded} = useReCaptcha()

logintext.value = gconfig.$langsmgr("langkey.component.login.submit.normal")
recaptchatext.value = gconfig.$langsmgr("langkey.component.login.recaptcha.normal")



// const recaptcha = async() => {
//     isrecaptchachecking.value = true;
//     recaptchatext.value = gconfig.$langsmgr("langkey.component.login.recaptcha.working")
//     var token = null;
//     try{
//         await recaptchaLoaded()
//         token = await executeRecaptcha('login')
//     } catch(e) {
//         //console.log("async error.")
//     }
//     if(token) {

//         axios.get(`${gconfig.$backendbase}/usrctl/v3/checkgtoken`, {
//                 params: {
//                     gtoken: encodeURIComponent(token),
//                     r: Math.random()
//                 }
//             }).then((response)=>{
//                 if(response.data.data.result){
//                     isrecaptchachecking.value = false;
//                     recaptchatext.value = gconfig.$langsmgr("langkey.component.login.recaptcha.success")
//                     logintext.value = gconfig.$langsmgr("langkey.component.login.submit.normal")
//                     recaptchatype.value = "text"
//                     isnotcheck.value = false

//                     gtoken.value = token
//                     __temp_sign = response.data.data.sign

//                     nextTick(()=>{
//                         unameinput.value.focus();
//                     })
//                 }else{
//                     isrecaptchachecking.value = false;
//                     recaptchatext.value = gconfig.$langsmgr("langkey.component.login.recaptcha.failed")
//                 }
//             }).catch((error)=>{
//                 isrecaptchachecking.value = false;
//                 recaptchatext.value = gconfig.$langsmgr("langkey.component.login.recaptcha.failed")
//                 if(error.code == "ERR_NETWORK")
//                     notification['error']({
//                         message: gconfig.$langsmgr("langkey.sysmsg.unknowerror"),
//                         description: gconfig.$langsmgr("langkey.sysmsg.checkandcontact"),
//                         duration: 3
//                     })
//             })

//     } else {
//         recaptchatext.value = gconfig.$langsmgr("langkey.component.login.recaptcha.failed")
//     }
// }

const register = ()=>{
    location.href="#/register"
}
const login = ()=>{
    isloginning.value = true;
    logintext.value = gconfig.$langsmgr("langkey.component.login.submit.working")
    axios.post(`${gconfig.$backendbase}/usrctl/v3/signin`,{
            signname: unameval.value,
            passwd: upasswdval.value,
            //gtoken: encodeURIComponent(gtoken.value),
            //sign: __temp_sign
        },{
            params:{ r: Math.random() }
        }).then((response)=>{
            isloginning.value = false;
            if(response.data.data.result){
                gconfig.$vuid = response.data.data.uid
                gconfig.$vusername = response.data.data.signname
                gconfig.$vtoken = response.data.data.token
                gconfig.$storage.set(gconfig.$storagekey_fep_vuid, gconfig.$vuid)
                gconfig.$storage.set(gconfig.$storagekey_fep_vsignname, gconfig.$vusername)
                gconfig.$storage.set(gconfig.$storagekey_fep_vtoken, gconfig.$vtoken)

                message.success("登入成功")
                gconfig.$uimenuselectedkey = "3"
                setTimeout(()=>{location.href="#/dashboard"},500)
            }else{
                logintext.value = gconfig.$langsmgr("langkey.component.login.submit.normal")

                notification['error']({
                    message: gconfig.$langsmgr("langkey.component.login.result.failed.message"),
                    description: gconfig.$langsmgr("langkey.component.login.result.failed.unknowusernameorpassworderror"),
                    duration: 3
                })
            }
        })
        .catch((error)=>{
            //console.log(error);
        })
}

const repasswd = ()=>{
    location.href="#/repasswd"
}



onMounted(()=>{
    whenWindowResize()
    window.addEventListener('resize', whenWindowResize)

    axios.get(`${gconfig.$backendbase}/global/v4/fingerprint`,{
        params: {r:Math.random()}
    });

    gconfig.$vtoken = gconfig.$storage.get(gconfig.$storagekey_fep_vtoken).value
    if(gconfig.$vtoken){
        axios.get(`${gconfig.$backendbase}/usrctl/v3/authinspection`, { 
            params: { r: Math.random() },
            headers: gconfig.$getauthheaders()
        }).then((response)=>{
            if(response.data.data.result) {
                gconfig.$vuid = response.data.data.uid
                gconfig.$vusername = response.data.data.signname
                gconfig.$vtoken = response.data.data.token
                gconfig.$uimenuselectedkey = "3"
                setTimeout(()=>{ location.href="#/dashboard"},500)
            } else isenvchecked.value = true
        }).catch((error)=>{
            isenvchecked.value = true
        })
    }else{
        isenvchecked.value = true
    }
})

</script>

<style src="./../assets/common.css" scoped></style>
<style scoped>
.bglayout{
    position:fixed;
    top:0;
    left: 0;
    width:100%;
    height:100%;
}
#bgvdo{
    position:fixed;
    top:-50px;
    left:0;
    width:auto;
    height:auto;
    z-index:0;
    object-fit: fill;
}
.loginbtnline{
    padding-top:20px;
}
.remeline{
    float: left;
}
.remeckbox{
    color:#999;
}
</style>