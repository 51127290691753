<template>
    <div class="cntfooter">
        Copyright 2022-2023 <a href="javascript:;">{{$langsmgr('langkey.sitename')}}</a> all rights reserved.
    </div>
</template>

<script setup>

</script>

<style scoped>
.cntfooter{
    text-align: center;
    font-size:12px;
    color:#cccccc;
    padding:15px 0 10px 0;
}
.cntfooter a{
    color:#cccccc;
    text-decoration: none;
}
.cntfooter a:hover{
    color:#666666;
    text-decoration: underline;
}
</style>