<template>
    <a-layout>
        <a-layout-sider v-if="winwidth>winwidthmodelimit&&!ismobile" v-model:collapsed="collapsed" :trigger="null" collapsible class="sidemenucontainer">
            <fep-sidemenus :selectedkey="$uimenuselectedkey" v-model:collapsed="collapsed"/>
        </a-layout-sider>
        <a-drawer v-else width="60%" placement="left" :title="$langsmgr('langkey.sitename.short')" :open="drawstate" :closable="false" @close="closeDraw">
            <template #extra>
                <menu-fold-outlined class="trigger" @click="closeDraw"></menu-fold-outlined>
            </template>
            <fep-sidemenus :selectedkey="$uimenuselectedkey" :collapsed="true"/>
        </a-drawer>
        <a-layout-header class="headcontainer">
            <div class="headright">
                <span class="headmenu noselect">
                    <fep-headmenus/>
                </span>
            </div>
            <div v-if="winwidth>winwidthmodelimit&&!ismobile">
                <span :class="winwidth>winwidthmodelimit&&collapsed?'pcleftcollapsed':'pcleft'"></span>
                <menu-unfold-outlined v-if="collapsed" class="trigger" @click="switchmenu"></menu-unfold-outlined>
                <menu-fold-outlined v-else class="trigger" @click="switchmenu"></menu-fold-outlined>
                <span style="margin-left:10px;color:#999999" class=noselect>{{$langsmgr(currentviewlankey)}}</span>
            </div>
            <div v-else>
                <menu-unfold-outlined class="trigger" @click="switchmenu"></menu-unfold-outlined>
                <span style="margin-left:10px;color:#999999" class=noselect>{{$langsmgr(currentviewlankey)}}</span>
            </div>
        </a-layout-header>
        <a-layout-content class="acontent" :class="winwidth>winwidthmodelimit&&!ismobile?collapsed?'acontentlite':'acontentfull':null">
            <div :class="!ismobile?(winwidth>winwidthmodelimit?'vcontentlite':'vcontentfull'):'vcontentfull'">
                <a-row>
                    <a-col :span="24">
                        finance page content
                    </a-col>
                </a-row>
            </div>
        </a-layout-content>
    </a-layout>
</template>
<script setup>
import {MenuFoldOutlined,MenuUnfoldOutlined,CaretDownOutlined} from '@ant-design/icons-vue'
import { ref,getCurrentInstance, onMounted } from 'vue'

const currentviewlankey = ref('langkey.menuitem.finance')
const winwidthmodelimit = 1320
const drawstate = ref(false)
const winwidth = ref(0)

const ginstance = getCurrentInstance()
const collapsed = ref(ginstance.appContext.config.globalProperties.$uimenucollapsed)
const ismobile = ref(ginstance.appContext.config.globalProperties.$ismobile())

onMounted(()=>{
    whenWindowResize()
    window.addEventListener('resize', whenWindowResize)
})
const whenWindowResize=()=>{ winwidth.value = window.innerWidth }
const switchmenu = () => {
    if(!ismobile.value&&winwidth.value>winwidthmodelimit) ginstance.appContext.config.globalProperties.$uimenucollapsed = collapsed.value = !collapsed.value
    else showDraw()
}
const showDraw = () => { drawstate.value = true }
const closeDraw = () => { drawstate.value = false }

</script>

<style src="./../assets/common.css" scoped></style>