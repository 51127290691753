<template>
    <a-layout>
        <a-layout-sider v-if="winwidth>winwidthmodelimit&&!ismobile" v-model:collapsed="collapsed" :width="250" :trigger="null" collapsible class="sidemenucontainer">
            <fep-sidemenus :selectedkey="$uimenuselectedkey" v-model:collapsed="collapsed"/>
        </a-layout-sider>
        <a-drawer v-else width="60%" placement="left" :title="$langsmgr('langkey.sitename.short')" :open="drawstate" :closable="false" @close="closeDraw">
            <template #extra>
                <menu-fold-outlined class="trigger" @click="closeDraw"></menu-fold-outlined>
            </template>
            <fep-sidemenus :selectedkey="$uimenuselectedkey" :collapsed="true"/>
        </a-drawer>
        <a-layout-header class="headcontainer">
            <div class="headright">
                <span class="headmenu noselect">
                    <fep-headmenus/>
                </span>
            </div>
            <div v-if="winwidth>winwidthmodelimit&&!ismobile">
                <span :class="winwidth>winwidthmodelimit&&collapsed?'pcleftcollapsed':'pcleft'"></span>
                <menu-unfold-outlined v-if="collapsed" class="trigger" @click="switchmenu"></menu-unfold-outlined>
                <menu-fold-outlined v-else class="trigger" @click="switchmenu"></menu-fold-outlined>
                <span style="margin-left:10px;color:#999999" class=noselect>{{$langsmgr(currentviewlankey)}}</span>
            </div>
            <div v-else>
                <menu-unfold-outlined class="trigger" @click="switchmenu"></menu-unfold-outlined>
                <span style="margin-left:10px;color:#999999" class=noselect>{{$langsmgr(currentviewlankey)}}</span>
            </div>
        </a-layout-header>
        <a-layout-content class="acontent" :class="winwidth>winwidthmodelimit&&!ismobile?collapsed?'acontentlite':'acontentfull':null">
            <div :class="!ismobile?(winwidth>winwidthmodelimit?'vcontentlite':'vcontentfull'):'vcontentfull'">
                <a-row>
                    <a-col :span="24">
                        <a-card class="rowcard textcenter" :bordered="false" :style="{borderRadius:'2px'}" :bodyStyle="{margin:0,padding:0}">
                            <div v-if="billdata.state==0x01">
                                <div><CheckCircleFilled class="signalsuccess"/></div>
                                <div class="signaltext">{{$langsmgr('langkey.component.billing.state.paid')}}</div>
                                <div class="signaldesc">{{$langsmgr('langkey.component.billing.state.paid.intro')}}</div>
                                <div class="signaldoc">
                                    <a-button type="primary" danger @click="viewdocument">
                                        <ReadOutlined/>
                                        {{$langsmgr('langkey.component.billing.state.paid.viewdoc')}}
                                    </a-button>
                                </div>
                            </div>
                            <div v-else-if="billdata.state==0x00">
                                <div><ClockCircleFilled class="signalwait"/></div>
                                <div class="signaltext">{{$langsmgr('langkey.component.billing.state.unpaid')}}</div>
                                <div class="signaldesc">{{$langsmgr('langkey.component.billing.state.unpaid.intro', {"{0}":''})}}</div>
                                <div class="signaldoc">
                                    <a-button type="primary" danger @click="closebilling">
                                        <CloseOutlined/>
                                        {{$langsmgr('langkey.component.billing.state.unpaid.close')}}
                                    </a-button>
                                </div>
                            </div>
                            <div v-else>
                                <div><CloseCircleFilled class="signalclosed"/></div>
                                <div class="signaltext">{{$langsmgr('langkey.component.billing.state.closed')}}</div>
                                <div class="signaldesc">{{$langsmgr('langkey.component.billing.state.closed.intro')}}</div>
                                <div class="signaldoc">
                                    <a-button type="primary" @click="purchase">
                                        <GiftOutlined/>
                                        {{$langsmgr('langkey.component.billing.state.closed.repurchase')}}
                                    </a-button>
                                </div>
                            </div>
                        </a-card>
                        <a-card class="rowcard" :title="$langsmgr('langkey.component.billing.title.productinfo')" :style="{borderRadius:'2px'}" 
                            :headStyle="{backgroundColor:'#f8f9fc'}" :bodyStyle="{padding:'20px'}">
                            <a-row :style="{padding:'10px',lineHeight:'30px'}">
                                <a-col :span="8" class="dkey">{{$langsmgr('langkey.component.billing.text.prodtype')}}</a-col>
                                <a-col :span="16" class="dval">{{billdata.packname}}</a-col>
                                <a-col :span="8" class="dkey">{{$langsmgr('langkey.component.billing.text.prodcate')}}</a-col>
                                <a-col :span="16" class="dval">{{billdata.planname}} ({{billdata.planduration}} {{$langsmgr('langkey.component.billing.text.circleunit')}})</a-col>
                                <a-col :span="8" class="dkey">{{$langsmgr('langkey.component.billing.text.prodflow')}}</a-col>
                                <a-col :span="16" class="dval">{{billdata.flowtotal}} GB</a-col>
                            </a-row>
                        </a-card>
                        <a-card class="rowcard" :title="$langsmgr('langkey.component.billing.title.billinfo')" :style="{borderRadius:'2px'}" 
                            :headStyle="{backgroundColor:'#f8f9fc'}" :bodyStyle="{padding:'20px'}">
                            <a-row :style="{padding:'10px',lineHeight:'30px'}">
                                <a-col :span="8" class="dkey">{{$langsmgr('langkey.component.billing.text.billno')}}</a-col>
                                <a-col :span="16" class="dval">{{billdata.billno}}</a-col>
                                <a-col :span="8" class="dkey">{{$langsmgr('langkey.component.billing.text.billcreatetime')}}</a-col>
                                <a-col :span="16" class="dval">{{billdata.createtime}}</a-col>
                                <a-col v-if="billdata.succtime" :span="8" class="dkey">{{$langsmgr('langkey.component.billing.text.billpaytime')}}</a-col>
                                <a-col v-if="billdata.succtime" :span="16" class="dval">{{billdata.succtime}}</a-col>
                            </a-row>
                        </a-card>
                    </a-col>
                </a-row>
                <Cntfooter/>
            </div>
        </a-layout-content>
    </a-layout>
</template>
<script setup>
import {MenuFoldOutlined,MenuUnfoldOutlined,CaretDownOutlined,CheckCircleFilled,ReadOutlined,ClockCircleFilled,CloseOutlined,CloseCircleFilled,GiftOutlined} from '@ant-design/icons-vue'
import { ref,getCurrentInstance, onMounted } from 'vue'
import { message, Modal } from 'ant-design-vue';
import Cntfooter from './cntfooter.vue'
import axios from 'axios'

const currentviewlankey = ref('langkey.menuitem.billing')
const winwidthmodelimit = 1320
const drawstate = ref(false)
const winwidth = ref(0)

const ginstance = getCurrentInstance()
const gconfig = ginstance.appContext.config.globalProperties
const collapsed = ref(gconfig.$uimenucollapsed)
const ismobile = ref(gconfig.$ismobile())
const billdata = ref({
    billno: null,
    packname: null,
    planname: null,
    duration: null,
    flowtotal: null,
    createtime: null,
    tick: null,
    state: null
})

onMounted(()=>{
    whenWindowResize()
    window.addEventListener('resize', whenWindowResize)


    loadbillingdata()
})
const whenWindowResize=()=>{ winwidth.value = window.innerWidth }
const switchmenu = () => {
    if(!ismobile.value&&winwidth.value>winwidthmodelimit) gconfig.$uimenucollapsed = collapsed.value = !collapsed.value
    else showDraw()
}
const showDraw = () => { drawstate.value = true }
const closeDraw = () => { drawstate.value = false }

const viewdocument = () => {
    gconfig.$uimenuselectedkey = '15'
    location.href="#/documents"
}

const loadbillingdata = () => {
    axios.get(`${gconfig.$backendbase}/billing/v3/getbilling`, {
        params: {
            bid: gconfig.$querystring("bn"),
            r: Math.random()
        },
        headers: gconfig.$getauthheaders()
    }).then((response) => {
        //console.log(response)
        if(response.data.data.result){
            billdata.value.billno = response.data.data.tradeno
            billdata.value.packname = decodeURIComponent(response.data.data.packname)
            billdata.value.duration = response.data.data.packduration
            billdata.value.flowtotal = response.data.data.packflowlimit
            billdata.value.createtime = response.data.data.regtime
            billdata.value.state = response.data.data.state
            billdata.value.planname = decodeURIComponent(response.data.data.planname)
            billdata.value.succtime = response.data.data.succtime
            billdata.value.planduration = response.data.data.planduration

            if(billdata.value.state==0x00) checkrechargestatus()
        }
    }).catch((error) => {
        gconfig.$axiosErrorHandle(error)
    })
}

const closebilling = () => {
    Modal.confirm({
        title: gconfig.$langsmgr('langkey.component.billing.msg.title'),
        content: gconfig.$langsmgr('langkey.component.billing.msg.content'),
        okText: gconfig.$langsmgr('langkey.component.billing.msg.ok'),
        cancelText: gconfig.$langsmgr('langkey.component.billing.msg.cancel'),
        onOk() {
            axios.get(`${gconfig.$backendbase}/billing/v3/closebilling`,{
                params: {
                    bid: gconfig.$querystring("bn"),
                    r: Math.random()
                },
                headers: gconfig.$getauthheaders()
            }).then((response)=>{
                //console.log(response)
                if(response.data.data){
                    billdata.value.state = 0x02
                    message.success(gconfig.$langsmgr('langkey.component.billing.msg.succ'))
                }else{
                    message.error(gconfig.$langsmgr('langkey.component.billing.msg.fail'))
                }
            }).catch((error)=>{
                gconfig.$axiosErrorHandle(error)
            })
        }
    })
}

const purchase = () => {
    location.href="#/plans"
}

const checkrechargestatus = () => {
    axios.get(`${gconfig.$backendbase}/recharge/v3/checkrechargestatus`,{
        params:{
            excpbn: billdata.value.billno,
            r: Math.random()
        },
        headers: gconfig.$getauthheaders()
    }).then(resp=>{
        //console.log(resp)
        billdata.value.tick = resp.data.data.tick
        if(resp.data.data.result) {
            billdata.value.state = resp.data.data.state
            billdata.value.succtime = resp.data.data.succtime
            //console.log(billdata.value.state)
            if(billdata.value.state == 0x00) setTimeout(()=>{checkrechargestatus()},10000)
        }
    }).catch(err=>{
        gconfig.$axiosErrorHandle(err)
    })
}

</script>

<style src="./../assets/common.css" scoped></style>
<style scoped>
.textcenter{
    text-align: center;
}
.signalsuccess{
    padding:30px;
    color:#52c41a;
    font-size: 72px;
}
.signalwait{
    padding:30px;
    color:#196ebf;
    font-size: 72px;
}
.signalclosed{
    padding:30px;
    color:#cccccc;
    font-size: 72px;
}
.signaltext{
    font-size:22px;
}
.signaldesc{
    font-size: 14px;
    color:#cccccc;
}
.signaldoc{
    padding:20px;
}
.dkey{
    color:#999999;
}
.dval{
    font-size:14px;
}
</style>