<template>
    <div>activities page</div>
</template>

<script>
import { createApp } from 'vue'

export default {

}
</script>

<style scoped>

</style>