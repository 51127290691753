import { getCurrentInstance } from 'vue'
import lang_zhcn from './lang/lang.zhcn.json'
import lang_enus from './lang/lang.enus.json'
import lang_zhtw from './lang/lang.zhtw.json'

const langslib = {}
var __app = null;
var __const_vlangcode = "fep_vlangcode"

const langsmgr = (langkey,replacements) => {
    const __langpack = langslib[__app.config.globalProperties.$vlangcode]
    var __entry = __langpack?__langpack.langdata[langkey]:null
    if(__entry&&replacements){
        for(var key in replacements) {
            var __reg = new RegExp(key.replace('{','\\{').replace('}','\\}'))
            __entry = __entry.replace(__reg, replacements[key])
        }
    }
    return __entry
}
const setsyslang = (langcode) => {
    __app.config.globalProperties.$vlangcode = langcode
    __app.config.globalProperties.$storage.set(__const_vlangcode, __app.config.globalProperties.$vlangcode)
}
const getsyslangs = () => {
    var __result = []
    for(var key in langslib) {
        __result.push({
            code: langslib[key].langcode,
            view: langslib[key].langview,
            last: false
        })
    }
    if(__result&&__result.length>0x00)
        __result[__result.length-0x01].last = true
    return __result
}
const currentlang = () => {
    const __langpack = langslib[__app.config.globalProperties.$vlangcode]
    return __langpack?__langpack.langview:"unknow language"
}

export default {
    install: (app) => {
        langslib[lang_enus.langcode] = lang_enus
        langslib[lang_zhcn.langcode] = lang_zhcn
        langslib[lang_zhtw.langcode] = lang_zhtw

        var __fslangcode = app.config.globalProperties.$storage.get(__const_vlangcode)
        if(__fslangcode.value)
            app.config.globalProperties.$vlangcode = __fslangcode.value

        app.config.globalProperties.$langsmgr = langsmgr
        app.config.globalProperties.$setsyslang = setsyslang
        app.config.globalProperties.$currentlang = currentlang
        app.config.globalProperties.$getsyslangs = getsyslangs

        __app = app
    }
}