<template>
    <div>tos page</div>
</template>

<script>
import { createApp } from 'vue'

export default {
    setup(){
        
    }
}
</script>

<style scoped>

</style>