<template>
    <a-layout>
        <a-layout-sider v-if="winwidth>winwidthmodelimit&&!ismobile" v-model:collapsed="collapsed" :width="250" :trigger="null" collapsible class="sidemenucontainer">
            <fep-sidemenus :selectedkey="$uimenuselectedkey" v-model:collapsed="collapsed"/>
        </a-layout-sider>
        <a-drawer v-else width="60%" placement="left" :title="$langsmgr('langkey.sitename.short')" :open="drawstate" :closable="false" @close="closeDraw">
            <template #extra>
                <menu-fold-outlined class="trigger" @click="closeDraw"></menu-fold-outlined>
            </template>
            <fep-sidemenus :selectedkey="$uimenuselectedkey" :collapsed="true"/>
        </a-drawer>
        <a-layout-header class="headcontainer">
            <div class="headright">
                <span class="headmenu noselect">
                    <fep-headmenus/>
                </span>
            </div>
            <div v-if="winwidth>winwidthmodelimit&&!ismobile">
                <span :class="winwidth>winwidthmodelimit&&collapsed?'pcleftcollapsed':'pcleft'"></span>
                <menu-unfold-outlined v-if="collapsed" class="trigger" @click="switchmenu"></menu-unfold-outlined>
                <menu-fold-outlined v-else class="trigger" @click="switchmenu"></menu-fold-outlined>
                <span style="margin-left:10px;color:#999999" class=noselect>{{$langsmgr(currentviewlankey)}}</span>
            </div>
            <div v-else>
                <menu-unfold-outlined class="trigger" @click="switchmenu"></menu-unfold-outlined>
                <span style="margin-left:10px;color:#999999" class=noselect>{{$langsmgr(currentviewlankey)}}</span>
            </div>
        </a-layout-header>
        <a-layout-content class="acontent" :class="winwidth>winwidthmodelimit&&!ismobile?collapsed?'acontentlite':'acontentfull':null">
            <div :class="!ismobile?(winwidth>winwidthmodelimit?'vcontentlite':'vcontentfull'):'vcontentfull'">
                <a-row>
                    <a-col :span="24">
                        <a-card v-if="usernosubscribe" :bordered="false" :title="null" class="rowcard" :style="{borderRadius:'2px',marginTop:'0'}" :hoverable="true">
                            <a class="nosubscribe" @click="buypackage">
                                <div class="plusicon"><PlusOutlined/></div>
                                <div>{{$langsmgr('langkey.component.services.buypackage')}}</div>
                            </a>
                        </a-card>
                        <a-card v-else :bordered="false" class="rowcard" :bodyStyle="{padding:0}" :style="{borderRadius:'2px',marginTop:'0'}">
                            <div slot="title">
                                <div>
                                    <div class="servtabhead">
                                        <a-row>
                                            <a-col span="12"><span class="tabcellleft">{{$langsmgr('langkey.component.services.tablecolumn.name')}}</span></a-col>
                                            <a-col span="5" :style="{textAlign:'center'}">
                                                <a-tooltip placement="top">
                                                    <template #title><span>{{$langsmgr('langkey.component.services.tooltips.status')}}</span></template>
                                                    <span>{{$langsmgr('langkey.component.services.tablecolumn.status')}}</span>
                                                    <QuestionCircleOutlined/>
                                                </a-tooltip>
                                            </a-col>
                                            <a-col span="4">
                                                <a-tooltip placement="top">
                                                    <template #title><span>{{$langsmgr('langkey.component.services.tooltips.rate')}}</span></template>
                                                    <span>{{$langsmgr('langkey.component.services.tablecolumn.rate')}}</span>
                                                    <QuestionCircleOutlined/>
                                                </a-tooltip>
                                            </a-col>
                                            <a-col span="3"><span class="tabcellleft markup">{{$langsmgr('langkey.component.services.tablecolumn.tags')}}</span></a-col>
                                        </a-row>
                                    </div>
                                    <a-divider :style="{margin:0}"/>
                                </div>
                            </div>
                            <a-skeleton active :loading="loading_nodes" :style="{padding:'20px'}">
                                <div v-if="userservnodes.length>0x00" v-for="node in userservnodes">
                                    <div class="servtabrow">
                                        <a-row>
                                            <a-col span="12"><span class="tabcellleft">{{node.name}}</span></a-col>
                                            <a-col span="5" :style="{textAlign:'center'}"><span><a-badge :color="node.stacolor"/></span></a-col>
                                            <a-col span="4"><span><a-tag class="ratiotag">{{node.ratio}} x</a-tag></span></a-col>
                                            <a-col span="3"><span class="tabcellleft markup">{{node.tag}}</span></a-col>
                                        </a-row>
                                    </div>
                                    <a-divider :style="{margin:0}"/>
                                </div>
                                <a-empty v-else :image="emptyImage"/>
                            </a-skeleton>
                            <div class="tabfooter">
                                <span class="caretright"><CaretRightOutlined/></span>
                                <span class="caretleft"><CaretLeftOutlined/></span>
                            </div>
                        </a-card>
                    </a-col>
                </a-row>
                <Cntfooter/>
            </div>
            <div>

            </div>
        </a-layout-content>
    </a-layout>
</template>
<script setup>
import {MenuFoldOutlined,MenuUnfoldOutlined,CaretDownOutlined,CloseOutlined,PlusOutlined,QuestionCircleOutlined,CaretLeftOutlined,CaretRightOutlined} from '@ant-design/icons-vue'
import {Empty} from 'ant-design-vue'
import { ref,getCurrentInstance, onMounted } from 'vue'
import Cntfooter from './cntfooter.vue'
import axios from 'axios'

const loading_nodes = ref(true)
const emptyImage = Empty.PRESENTED_IMAGE_SIMPLE

const currentviewlankey = ref('langkey.menuitem.services')
const winwidthmodelimit = 1320
const drawstate = ref(false)
const winwidth = ref(0)

const ginstance = getCurrentInstance()
const gconfig = ginstance.appContext.config.globalProperties
const collapsed = ref(gconfig.$uimenucollapsed)
const ismobile = ref(gconfig.$ismobile())
const documentshowstate = ref(false)
const usernosubscribe = ref(false)

const userservnodes = ref([])

onMounted(()=>{
    whenWindowResize()
    window.addEventListener('resize', whenWindowResize)

    axios.get(`${gconfig.$backendbase}/nodes/getservicenodes`,{
        params: { r: Math.random() },
        headers: gconfig.$getauthheaders()
    }).then((response)=>{
        usernosubscribe.value = !(response.data.data.haspack)
        if(response.data.data.result){
            userservnodes.value.splice(0x00,usernosubscribe.value.length)
            for (var i = 0x00; i < response.data.data.ucgroups.length; i++) {
                var __group = response.data.data.ucgroups[i];
                for (var n = 0x00; n < __group.ucnodes.length; n++) {
                    var __node = __group.ucnodes[n]
                    userservnodes.value.push({
                        name: decodeURIComponent(__node.displayname),
                        ratio: __node.multiple.toFixed(0x02),
                        state: __node.state,
                        stacolor: __node.state == 0x00 ? 'gray' : 
                            __node.state == 0x01 ? 'green' : 'red',
                        tag: decodeURIComponent(__node.remark)
                    })
                }
            }
        }
        loading_nodes.value = false
    }).catch((error)=>{
        gconfig.$axiosErrorHandle(error)
    })

})
const whenWindowResize=()=>{ winwidth.value = window.innerWidth }
const switchmenu = () => {
    if(!ismobile.value&&winwidth.value>winwidthmodelimit) gconfig.$uimenucollapsed = collapsed.value = !collapsed.value
    else showDraw()
}
const showDraw = () => { drawstate.value = true }
const closeDraw = () => { drawstate.value = false }

const buypackage = () => {
    gconfig.$uimenuselectedkey = "10"
    location.href="#/plans"
}

</script>

<style src="./../assets/common.css" scoped></style>
<style scoped>
.nosubscribe {
    display:block;
    text-align: center;
    margin:30px auto;
    color:#999999;
}
.nosubscribe:hover{
    color:#666666;
}
.plusicon{
    font-size:35px;
    font-weight:bolder;
}
.servtabhead{
    padding: 15px 20px;
}
.servtabhead span{
    padding-right:5px;
}
.servtabrow {
    padding: 15px 20px;
}
.servtabrow:hover{
    background-color:#e6f6ff;
}
.tabcellleft{
    float: left;
}
.markup{
    padding-left:10px;
}
.ratiotag{
    background-color:#f0f0f0;
    color:#666666;
    width:60px;
    text-align: center;
}
.tabfooter{
    background-color:#f1f1f1;
    color:#a3a3a3;
    text-align: left;
    height:16px;
    line-height:16px;
    font-size: 10px;
}
.caretletf{
    float:left;
    padding-left:5px;
}
.caretright{
    float:right;
}
</style>